<template>
    <div>
        <b-row>
            <b-col cols="12">
                <b-card class="my-card m-0">
                    <b-table 
                    striped
                    small
                    id="journalPromo"
                    bordered
                    selectable
                    selected-variant="primary"
                    select-mode="single"
                    responsive="sm"
                    :tbody-tr-class="rowClass"
                    :items="promocodes"
                    :fields="fields"
                    @row-clicked="clicked"
                    @row-contextmenu="handleContextMenuEvent"
                    head-row-variant="primary"
                    >
                </b-table>
                <vue-context ref="menu">
              <li>
                <b-link
                class="d-flex align-items-center"
                v-b-modal.edit_promo
                >
                  <feather-icon
                    icon="Edit3Icon"
                  />
                <span class="ml-75">Изменить</span>
                </b-link>
                <b-link
                class="d-flex align-items-center"
                v-b-modal.delete_promo
                >
                  <feather-icon
                    icon="EditIcon"
                  />
                <span class="ml-75">Удалить</span>
                </b-link>
                <b-link
                class="d-flex align-items-center"
                v-b-modal.promo_block
                >
                  <feather-icon
                    icon="LockIcon"
                  />
                <span class="ml-75">Заблокировать промокод</span>
                </b-link>
              </li>
            </vue-context>
            </b-card>
        </b-col>
        </b-row>
    </div>
</template>

<script>
import VueContext from 'vue-context'
import { cellSelect } from "@/utils/TableFunctions/cellSelect";
export default {
    components: {
        VueContext
    },
    name: "journalPromo",
    props: ['promocodes'],
    data() {
        return {
            fields: [
          {key: 'id', label: 'ID', sortable: true, thStyle: {width: '50px'}},
          {key: 'organization_name', label: 'Организация', sortable: true, thStyle: {width: '50px'}},
          {key: 'code', label: 'Код', sortable: true, thStyle: {width: '50px'}},
          {key: 'division_name', label: 'Подразделения', sortable: true, thStyle: {width: '50px'}},
          {key: 'count_activation', label: 'Кол-во активаций', sortable: true, thStyle: {width: '50px'}},
          {key: 'promocode_type_name', label: 'Тип промокода', sortable: true, thStyle: {width: '50px'}},
        //   {key: '1', label: 'Телефон владельца', sortable: true, thStyle: {width: '50px'}},
        //   {key: '2', label: 'Логин водителя', sortable: true, thStyle: {width: '50px'}},
          {key: 'start_time', label: 'Начало', sortable: true, thStyle: {width: '50px'}},
          {key: 'and_time', label: 'Окончание', sortable: true, thStyle: {width: '50px'}},
        //   {key: '6', label: 'Кол-во попыток активации', sortable: true, thStyle: {width: '50px'}},
        //   {key: '7', label: 'Доступно активаций', sortable: true, thStyle: {width: '50px'}},
          {key: 'model_type', label: 'Тип', sortable: true, thStyle: {width: '50px'}},
          {key: 'amount', label: 'Сумма', sortable: true, thStyle: {width: '50px'}},
        //   {key: '10', label: 'Условия активации', sortable: true, thStyle: {width: '50px'}},
          {key: 'comment', label: 'Описание', sortable: true, thStyle: {width: '50px'}},
        //   {key: '11', label: 'Число месяцев клиента без заказов', sortable: true, thStyle: {width: '50px'}},
        //   {key: '12', label: 'Период отсутствия активности клиента (в днях)', sortable: true, thStyle: {width: '50px'}},
        //   {key: '13', label: 'Аудит', sortable: true, thStyle: {width: '50px'}},
        ],
        // test: [{
        //     category_id: 10
        // }]
        }
    },
    methods: {
        handleContextMenuEvent(item, index, event) {
          console.log(item.id);
          this.$emit('id', item.id);
          event.preventDefault();
          this.$refs.menu.open(event);
          this.selectedId = item.id;
          localStorage.setItem('id', this.selectedId)
        },
        rowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.is_active === 10) return 'table-danger'
            else if (item.is_active === 1) return 'table-success'
            else if (item.is_active === 3) return 'table-secondary'
            else if (item.is_active === 2) return ''
        },
        clicked(item, index, event){
            this.$store.state.filterPlus.tableIndex = index

            const clickedElement = event.target;
            const clickedCell = clickedElement.closest('td');

            if (!clickedCell) {
                return;
            }

            const columnIndex = Array.from(clickedCell.parentNode.children).indexOf(clickedCell);

            if (columnIndex < 0 || columnIndex >= this.fields.length) {
                return;
            }

            const clickedField = this.fields[columnIndex];
            this.$store.state.filterPlus.keyFilter = clickedField.key;
            this.$store.state.filterPlus.filterPlus = (event.target.innerHTML).trim()
            cellSelect()
        },
    },
}
</script>
