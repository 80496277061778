<template>
  <div>
    <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh;">
          <b-spinner variant="primary" label="Text Centered" />
    </div>
    <div v-else>
      <table-journal-promo :promocodes="promocodes" @refresh="refresh" @id="setId"></table-journal-promo>
      <modal-promo-block :id="currentId" @refresh="refresh"></modal-promo-block>
      <modal-delete-promo :id="currentId" @refresh="refresh"></modal-delete-promo>
      <modal-promo-add></modal-promo-add>
      <modal-journal-edit></modal-journal-edit>
    </div>
    <filter-modalVue :fields="fields" @sendToParent="sendToParent"></filter-modalVue>
  </div>
</template>

<script>
  import ModalDeletePromo from '@/views/component/Modal/ModalJournalEdit/ModalDeletePromo.vue'
  import ModalJournalEdit from '@/views/component/Modal/ModalJournalEdit/ModalJournalEdit.vue'
  import VueContext from 'vue-context'
  import ModalPromoAdd from '@/views/component/Modal/ModalJournalPromo/ModalPromoAdd.vue'
  import ModalPromoBlock from '@/views/component/Modal/ModalJournalPromo/ModalPromoBlock.vue'
  import tableJournalPromo from '@/views/component/Table/tableJournalPromo.vue'
export default {
  components:{
    VueContext,
    ModalJournalEdit,
    ModalPromoAdd,
    ModalDeletePromo,
    ModalPromoBlock,
    tableJournalPromo
  },
  data(){
    return{
      currentId:null,
      showPreloader:false,
      promocodes:[],
      fields: [
        { key: 'checkbox', label: '', thStyle: {width: '30px'}},
        { key: 'id', label: 'Id', sortable: true, thStyle: {width: '40px'}},
        { key: 'name', label: 'Имя', sortable: true, thStyle: {width: '70px'}},
        { key: 'division', label: 'Подразделение', sortable: true, thStyle: {width: '150px'}},
        { key: 'calc_type', label: 'Тип расчета', sortable: true, thStyle: {width: '150px'}},
        { key: 'delivery_price', label: 'Цена подачи', sortable: true, thStyle: {width: '150px'} },
        { key: 'min_price', label: 'Минимальная цена', sortable: true, thStyle: {width: '150px'} },
        { key: 'is_active', label: 'Статус', sortable: true, thStyle: {width: '70px'}},
      ]
    }
  },
  mounted(){
    this.$store.commit('pageData/setdataCount', null)
    this.openFilter()
    this.refresh()
  },
  methods:{
    setId(id) {
      this.currentId = id;
    },
    openFilter(){
            let allRoutes = this.$store.state.draggableTab.tabs
            let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
            let arrayRoutes = []
            allRoutes.forEach(element => {
                arrayRoutes.push(element.path)
            });
            if (arrayRoutes.includes(this.$route.path) == true) {
                this.showPreloader = true
                this.$http
                    .get(`${this.$route.name}`, {params: param}).then(res => {
                        this.promocodes = res.data
                        this.$store.commit('pageData/setdataCount', this.promocodes.length)
                        this.showPreloader = false
                        this.$store.commit('REFRESH_DATA', false)
                    })
            }else if(arrayRoutes.includes(this.$route.path) == false){
                this.$bvModal.show(this.$route.name + 'filter')
            }
        },
      refresh(){
        this.showPreloader = true
        this.$http
        .get('promo-code')
        .then(res => {
          this.promocodes = res.data
          this.$store.commit('pageData/setdataCount', this.promocodes.length)
          this.$showPreloader = false
          this.$store.commit('REFRESH_DATA', false)
        })  
      },
      sendToParent(tableData){
          this.promocodes = tableData
          this.$store.commit('pageData/setdataCount', this.promocodes.length)
      }
  },
  computed:{
    fetchingNewData(){
      return this.$store.state.refresh.fetchingNewData
    }
  },
  watch: {
    fetchingNewData(val){
      if (val){
          this.$http
          .get(`${this.$route.name}`)
          .then(res =>{
              this.promocodes = res.data
              this.$store.commit('pageData/setdataCount', this.promocodes.length)
              this.$store.commit('REFRESH_DATA', false)
          })
        }
      }
    }
}
</script>

<style>

</style>